import { setTableToolTip } from "@/components/inventory/Audit/audit.utils";
import ConfirmModalComponent from "@/components/sharedComponents/confirm/confirm.component";
import { ResetService } from "@/decorators/resetService.decorator";
import { Audit, AuditStatus } from "@/interfaces/audit";

import { AuditMetadata } from "@/metadata/audit";
import { auditActionService } from "@/services/audit.action.service";
import { auditService } from "@/services/audit.service";
import { messagesService } from "@/services/messages.service";
import { PageNavAction } from "@/types/types";

import { policyList } from "@/enums/permissions";
import { EventBus } from "@/event-bus";
import { i18n } from "@/plugins/i18n";
import {
  BooleanCheck,
  TableAction,
  TableComponent,
  TableHeader,
  TablePagination,
  TablePaginationDefault
} from "helix-vue-components";
import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import Template from "./AuditList.template.vue";
import AuditModalComponent from "./auditModal/AuditModal.component";

const getMsgWhenError = (item: Audit) => {
  item.status =
    item.has_errors && item.summary.status === AuditStatus.OPENED
      ? AuditStatus.TRC_CONNECTION_ERROR
      : item.summary.status;
  const typeErrorMsg: { [key: string]: string } = {
    ERROR: String(
      item.summary.fractions_counted || item.summary.fractions_remaining
        ? i18n.t("audit.btrck_error")
        : i18n.t("audit.btrck_fatal_err")
    ),
    TRC_CONNECTION_ERROR: item.has_errors
      ? `${i18n.t("audit.generic_msg1").toString()} ${i18n.t(
          "audit.generic_msg2"
        )}`
      : i18n.t("audit.mtrc_error").toString()
  };
  return typeErrorMsg[item.status];
};
@Component({
  mixins: [Template],
  components: {
    TableComponent
  },
  inject: ["$changes"]
})
export default class AuditListComponent extends Vue {
  public audits: Audit[] = [];
  public pagination: TablePagination = { ...TablePaginationDefault };
  public loading = false;
  @Action("setPageNav", { namespace: "PageNavModule" })
  public setPageNav!: PageNavAction;
  public headers: TableHeader[] = AuditMetadata;
  public rowActions: TableAction[] = [];
  public currentInfo = "";
  public showClassPop = false;
  public hasModifyPermission = true;
  @Getter("hasPermission", { namespace: "PermissionsModule" })
  public hasPermission!: BooleanCheck;

  public changePagination(pagination: TablePagination) {
    this.pagination = pagination;
    this.loadAudit();
  }

  public filter(header: TableHeader) {
    auditService.sortQuery(header);
  }

  public reloadAudits() {
    this.loadAudit(false);
  }

  public async loadAudit(loading: boolean = true) {
    this.loading = loading;
    const data: Audit[] = await auditService.getAudits(this.pagination);
    this.pagination = await auditService.getPagination();
    this.audits = data.map(a => ({
      ...a,
      status:
        a.has_errors && a.summary.status === AuditStatus.OPENED
          ? AuditStatus.TRC_CONNECTION_ERROR
          : a.summary.status,
      errorsMsg: getMsgWhenError(a),
      rooms_table: a.inventory_locations!.map(i => i.name).join(", "),
      product_categories_table: a
        .product_categories!.map(i => i.name)
        .join(", ")
    }));

    this.loading = false;
  }

  public setMsgToolTip(data: string, error: boolean) {
    this.currentInfo = data;
    this.showClassPop = error;
  }

  public mounted() {
    this.loadAudit();
    this.hasModifyPermission = this.hasPermission(
      policyList.modifyInventoryAudit
    );
    this.rowActions = auditActionService.getRowActions(
      this.editAudit,
      this.cancelAudit,
      this.hasModifyPermission
    );
  }

  public cancelAudit(currentAudit: { item: Audit; unselectModal: () => void }) {
    const index = this.audits.findIndex(
      a => a.operation_uid === currentAudit.item.operation_uid
    );
    auditService.cancelAudit(currentAudit.item.reference).then(response => {
      currentAudit.unselectModal();
      if (response) {
        this.loadAudit();
      }
    });
  }

  public async newAudit() {
    try {
      await this.$modals.load<Audit>(AuditModalComponent, {
        title: this.$t("audit.inventory_audit").toString(),
        size: "fit",
        positionY: "top"
      });
      messagesService.showMessage(
        "fal fa-exclamation-triangle",
        String(this.$t("audit.audit_msg_building")),
        "warning"
      );
      this.loadAudit();
    } catch (e) {
      // avoid uncaught rejection.
    }
  }

  public goEditAudit(currentAudit: Audit) {
    this.$router.push({
      name: this.hasModifyPermission ? "audit-edit" : "audit-view",
      params: {
        operationId: currentAudit.operation_uid
      }
    });
  }

  public editAudit(currentAudit: Audit) {
    if (
      currentAudit.real_time_audit &&
      currentAudit.summary.status === AuditStatus.OPENED
    ) {
      this.modalAlert(currentAudit, {
        action: this.goEditAudit,
        title: "audit.real_time_audit",
        body: "audit.entry_real_time_msg"
      });
    } else {
      this.goEditAudit(currentAudit);
    }
  }

  public modalAlert(
    currentAudit: Audit,
    data: {
      action: (audit: Audit) => void;
      title: string;
      body: string;
    },
    icon: string = "fal fa-exclamation-triangle"
  ) {
    this.$modals
      .load(
        ConfirmModalComponent,
        {
          size: "normal",
          positionY: "center"
        },
        {
          modalData: {
            title: data.title,
            body: this.$t(data.body),
            icon
          }
        }
      )
      .then(
        () => {
          data.action(currentAudit);
        },
        () => {
          /** Nothing to do */
        }
      );
  }
  @Watch("audits")
  public toolTip() {
    setTableToolTip({
      audits: this.audits,
      rooms: document.getElementsByClassName("inner-pop-room"),
      categories: document.getElementsByClassName("inner-pop-categories"),
      error: document.getElementsByClassName("inner-pop-error"),
      toolTip: this.$refs["pop-over"],
      onRefreshToolTip: this.setMsgToolTip
    });
  }

  protected created() {
    this.setPageNav({
      title: "audit.audits",
      rightActions: {
        generalActions: () =>
          auditActionService.getGeneralActions(this.newAudit, this.loadAudit)
      }
    });
    EventBus.$on("reloadAudits", this.reloadAudits);
  }
}
