import { policyList } from "@/enums/permissions";
import { Audit, AuditStatus } from "@/interfaces/audit";
import { store } from "@/internal";
import { i18n } from "@/plugins/i18n";
import { ActionSubheader, TableAction } from "helix-vue-components";
class AuditActionService {
  public getGeneralActions(
    newAudit: () => void,
    loadAudit: () => void
  ): ActionSubheader[] {
    return [
      {
        icon: "fal fa-plus",
        action: newAudit,
        tooltip: {
          text: "audit.new_audit",
          displayCondition: () => true
        },
        visibleCondition: () =>
          store.getters["PermissionsModule/hasPermission"](
            policyList.createInventoryAudit
          )
      },
      {
        icon: "far fa-sync-alt",
        action: loadAudit,
        tooltip: {
          text: "audit.reload_audits",
          displayCondition: () => true
        }
      }
    ];
  }

  public getRowActions(
    editAudit: (currentAudit: Audit) => void,
    cancelAudit: (
      currentAudit: {
        item: Audit;
        unselectModal: () => void;
      }
    ) => void,
    hasModifyPermission: boolean
  ): TableAction[] {
    return [
      {
        icon: "fal fa-eye",
        action: editAudit,
        visibleCondition: arg => {
          return ([
            AuditStatus.PAUSED,
            AuditStatus.CLOSED,
            AuditStatus.CANCELED
          ] as string[]).includes(arg.item.status);
        }
      },
      {
        icon: hasModifyPermission ? "fal fa-pen" : "fal fa-eye",
        action: editAudit,
        visibleCondition: ({ item }: { item: Audit }) => {
          return (
            [AuditStatus.OPENED, AuditStatus.TRC_CONNECTION_ERROR].includes(
              item.summary.status
            ) ||
            (AuditStatus.ERROR &&
              (!!item.summary.fractions_counted ||
                !!item.summary.fractions_remaining))
          );
        }
      },
      {
        icon: "fal fa-times-circle",
        visibleCondition: arg => {
          return (
            !([
              AuditStatus.CLOSED,
              AuditStatus.CANCELED,
              AuditStatus.BUILDING
            ] as string[]).includes(arg.item.status) &&
            store.getters["PermissionsModule/hasPermission"](
              policyList.deleteInventoryAudit
            )
          );
        },
        vuetifyProps: arg => {
          return {
            loading: arg && arg.item.status === AuditStatus.LOADING
          };
        },
        modalActions: {
          modalNumber: 1,
          modalQuestion: i18n.t("audit.audit_canceled_confirm").toString(),
          modalSuccessText: "yes",
          modalSuccessAction: cancelAudit,
          modalCancelText: "No"
        }
      }
    ];
  }
}

export const auditActionService = new AuditActionService();
