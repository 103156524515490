import { Audit, AuditStatus } from "@/interfaces/audit";
import { Vue } from "vue-property-decorator";
interface ToolTip {
  audits: Audit[];
  rooms: HTMLCollectionOf<Element>;
  categories: HTMLCollectionOf<Element>;
  error: HTMLCollectionOf<Element>;
  toolTip: Element | Vue | Vue[] | Element[];
  onRefreshToolTip: (info: string, error: boolean) => void;
}
export const setTableToolTip = (data: ToolTip) => {
  setTimeout(() => {
    const itemWithError = data.audits.filter(item =>
      [AuditStatus.ERROR, AuditStatus.TRC_CONNECTION_ERROR].includes(
        item.status
      )
    );
    data.audits.forEach((element, index) => {
      (data.rooms[index] as HTMLElement).onmouseover = () => {
        setValueToolTip(data, element.rooms_table, data.rooms[
          index
        ] as HTMLElement);
      };
      (data.categories[index] as HTMLElement).onmouseover = () => {
        setValueToolTip(data, element.product_categories_table, data.categories[
          index
        ] as HTMLElement);
      };

      if (
        [AuditStatus.ERROR, AuditStatus.TRC_CONNECTION_ERROR].includes(
          element.status
        )
      ) {
        const indexError = itemWithError.findIndex(el => el.id === element.id);
        (data.error[indexError] as HTMLElement).onmouseover = () => {
          setValueToolTip(
            data,
            element.errorsMsg,
            data.error[indexError] as HTMLElement,
            true
          );
        };
      }
    });
  }, 300);
};

const setValueToolTip = (
  data: ToolTip,
  msg: string,
  currentElement: HTMLElement,
  error?: boolean
) => {
  if (currentElement.offsetWidth < msg.length * 8) {
    setTimeout(() => {
      (data.toolTip as HTMLElement).style.top = `${(currentElement.offsetParent as HTMLElement)
        .offsetTop + 20}px`;
      (data.toolTip as HTMLElement).style.left = `${currentElement.offsetLeft +
        20}px`;
      (data.toolTip as HTMLElement).style.width = `${
        currentElement.offsetWidth > msg.length * 8
          ? msg.length * 8
          : currentElement.offsetWidth
      }px`;
      data.onRefreshToolTip(msg, !!error);
    }, 300);
  }
};
