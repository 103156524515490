import { ProductCategory } from "@/interfaces/productCategoy";
import { Room } from "@/interfaces/room";
import { auditService } from "@/services/audit.service";
import debounce from "lodash/debounce";
import unionBy from "lodash/unionBy";
import cloneDeep from "lodash/unionBy";
import { Component, Vue, Watch } from "vue-property-decorator";
import Template from "./AuditModal.template.vue";
@Component({
  mixins: [Template]
})
export default class AuditModalComponent extends Vue {
  public rooms: Room[] = [];
  public productCategories: ProductCategory[] = [];
  public categoriesSelected: ProductCategory[] = [];
  public categoryName: string[] = [];
  public roomSelected: number[] = [];
  public searchCategoryField: string | null = null;
  public isLoadingCategories: boolean = false;
  public isLoading = false;
  public categoryKey = 0;
  public categoriesFound: ProductCategory[] = [];
  public initialCategories: ProductCategory[] = [];
  public realTime = false;
  public selectAll = false;
  public icon = "far fa-square";
  public debounceCategorySearch = debounce(
    async (scope: any, categoryName: string) => {
      if (categoryName && categoryName.length > 2) {
        scope.isLoadingCategories = true;
        scope.categoriesFound = await auditService.searchProductCategories(
          categoryName
        );

        scope.isLoadingCategories = false;
        scope.selectAll = false;
        scope.setListCategories();
        scope.onChangeList();
      }
    },
    500
  );

  @Watch("searchCategoryField")
  public async searchData(categoryName: string) {
    this.debounceCategorySearch(this, categoryName);
  }

  public async created() {
    this.isLoading = true;
    this.rooms = await auditService.searchRooms();
    this.isLoading = false;
    this.isLoadingCategories = true;
    this.categoriesFound = await auditService.searchProductCategories();
    this.initialCategories = cloneDeep(this.categoriesFound);
    this.isLoadingCategories = false;
    this.setListCategories();
    this.onSelectedAll();
  }

  get getCategoriesSelectedIds() {
    return this.categoriesSelected.map(c => c.id) as number[];
  }

  public async openAudit() {
    this.isLoading = true;
    const resp = await auditService.openAudit(
      this.roomSelected,
      this.getCategoriesSelectedIds,
      this.realTime
    );
    if (resp) {
      this.$emit("resolve");
    }
    this.isLoading = false;
  }
  public cancel() {
    this.$emit("reject");
  }

  public onChangeList() {
    this.selectAll =
      this.categoriesSelected.length === this.productCategories.length;
    if (!this.categoriesSelected.length) {
      this.categoriesSelected = [];
      this.setListCategories();
    }
    this.setChecks();
  }

  public setListCategories() {
    this.productCategories = unionBy(
      this.categoriesFound,
      this.categoriesSelected,
      "id"
    );
    this.categoryKey = Math.random();
  }

  public setChecks() {
    this.icon = this.selectAll ? "fas fa-check-square" : "far fa-square";
  }

  public checkSelected() {
    if (this.selectAll) {
      this.categoriesSelected = cloneDeep(this.productCategories);
    } else {
      this.categoriesSelected = [];
      this.productCategories = unionBy(
        this.categoriesFound,
        this.initialCategories,
        "id"
      );
    }
  }

  public onSelectedAll() {
    this.selectAll = !this.selectAll;
    this.checkSelected();
    this.setChecks();
  }
}
